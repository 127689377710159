import MuicreateTheme from '@mui/material/styles/createTheme';

/**
 * wrapped in a try catch as jest test is unable to compile
 */
const createTheme = () => {
  try {
    return MuicreateTheme({
      components: {
        MuiButtonBase: {
          defaultProps: {
            disableRipple: true,
          },
        },
        MuiSkeleton: {
          defaultProps: {
            animation: 'wave',
            sx: {
              bgcolor: 'rgba(145,158,171,0.3)',
              '&::after': {
                background: 'linear-gradient(90deg, transparent, rgba(145,158,171,0.25), transparent)',
              },
            },
          },
        },
      },
    });
  } catch {
    //
    return {};
  }
};

// required for material-ui to work
export const MUI_THEME = createTheme();
