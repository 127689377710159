export const HealthIcon: React.FunctionComponent = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 1C9.82441 1 7.69767 1.64514 5.88873 2.85383C4.07979 4.06253 2.66989 5.78049 1.83733 7.79048C1.00477 9.80047 0.786929 12.0122 1.21137 14.146C1.6358 16.2798 2.68345 18.2398 4.22183 19.7782C5.76021 21.3166 7.72022 22.3642 9.85401 22.7886C11.9878 23.2131 14.1995 22.9952 16.2095 22.1627C18.2195 21.3301 19.9375 19.9202 21.1462 18.1113C22.3549 16.3023 23 14.1756 23 12C22.9966 9.08367 21.8365 6.28778 19.7744 4.22563C17.7122 2.16347 14.9163 1.00344 12 1ZM12 3C14.2126 3.003 16.3468 3.82005 17.9957 5.29543C19.6446 6.7708 20.693 8.80133 20.941 11H16C15.8 10.9999 15.6046 11.0598 15.439 11.1719C15.2734 11.2841 15.1452 11.4433 15.071 11.629L14 14.307L10.929 6.629C10.8548 6.44342 10.7267 6.28434 10.5612 6.17228C10.3957 6.06023 10.2004 6.00033 10.0005 6.00033C9.80064 6.00033 9.60535 6.06023 9.43985 6.17228C9.27435 6.28434 9.14622 6.44342 9.072 6.629L7.323 11H3.05901C3.30703 8.80133 4.35541 6.7708 6.00433 5.29543C7.65324 3.82005 9.78739 3.003 12 3ZM12 21C9.78739 20.997 7.65324 20.1799 6.00433 18.7046C4.35541 17.2292 3.30703 15.1987 3.05901 13H8C8.19983 12.9999 8.39503 12.9399 8.56044 12.8278C8.72584 12.7157 8.85387 12.5566 8.92801 12.371L10 9.692L13.071 17.371C13.1451 17.5569 13.2732 17.7163 13.4388 17.8286C13.6044 17.9409 13.7999 18.0009 14 18.0009C14.2001 18.0009 14.3956 17.9409 14.5612 17.8286C14.7268 17.7163 14.855 17.5569 14.929 17.371L16.677 13H20.941C20.693 15.1987 19.6446 17.2292 17.9957 18.7046C16.3468 20.1799 14.2126 20.997 12 21Z"
        fill="currentColor"
      />
    </svg>
  );
};
