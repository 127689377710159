import * as React from 'react';
import { type PopoverProps } from '@mui/material/Popover';

export interface UsePopoverResult {
  anchorEl?: null | PopoverProps['anchorEl'];
  handleOpen: (event: React.MouseEvent<HTMLElement>) => void;
  handleClose: () => void;
  open: boolean;
  id?: string;
}

/** usePopover implements the logic to hide and show popover component and menu options
 *  we would have had to replicate this logic anywhere we want to use options
 * use whenever you are working with:
 * OptionsMenu component
 * Popover component
 * DatePicker component  */

export const usePopover = (_id: string): UsePopoverResult => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const id = anchorEl ? _id : undefined;

  return {
    handleOpen,
    handleClose,
    open,
    anchorEl,
    id,
  };
};
