import * as React from 'react';
import qs from 'qs';
import { useNavigate, useLocation } from 'react-router-dom';
import { type Device } from 'src/utils/api/get-orgs-promise/types-and-schema';
import { FilterValues } from 'src/types';
import { TZ } from 'src/constants/others/timezones';
import { type UserReportRangeType } from 'src/utils/api/retry-user-report/retry-user-report';
import { PARSE_OPTIONS, STRINGIFY_OPTIONS } from './constant';

export interface SearchParams {
  zone_type?: string;
  page?: string | number;
  search?: string;
  device?: Device;
  org_tab?: string | number;
  tableRows?: string[];
  filter?: FilterValues;
  date?: string;
  taskId?: string;
  log_type?: Array<string>;
  org_sort?: 'name' | 'image_date';
  user_report_range_type?: UserReportRangeType;
  tz?: TZ;
}

type SearchParamsUpdater = (currentParams: SearchParams) => SearchParams;

/** useSearch hook to read and write values of any data structure to search params */
export const useSearch = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = React.useMemo(() => qs.parse(location.search, PARSE_OPTIONS), [location.search]);
  const searchParamsRef = React.useRef<SearchParams>({});

  React.useEffect(() => {
    searchParamsRef.current = searchParams;
  }, [searchParams]);

  const setSearchParams = React.useCallback(
    (updater: SearchParamsUpdater, shouldReplace?: boolean) => {
      const params = typeof updater === 'function' ? updater(searchParamsRef.current) : updater;

      navigate(
        {
          ...location,
          search: qs.stringify(params, STRINGIFY_OPTIONS),
        },
        {
          replace: shouldReplace,
        }
      );
    },
    [navigate, location]
  );

  return [searchParams as SearchParams, setSearchParams] as const;
};
