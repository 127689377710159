import ky from 'ky';
import { z } from 'zod';
import { ENV } from 'src/utils/env';
import { generateVRID, generateUUID } from 'src/utils';
import { BoundingBoxConfig } from 'src/types';

const PRESENCE_ZONE_SCHEMA = z.object({
  uuid: z.string(),
  name: z.string(),
  min_time: z.number(),
  poly: z.array(z.array(z.number())),
  id: z.string().optional().nullable(),
  epos_filter_field: z.string().nullable().optional(),
  epos_filter: z.string().nullable().optional(),
  category: z.string(),
  checkout_id: z.string().nullable().optional(),
  epos_filters: z
    .array(
      z.object({
        filter_name: z.string(),
        filter_value: z.string(),
        not_flag: z.boolean(),
      })
    )
    .nullable()
    .optional(),
});

const GET_PRESENCE_ZONE_SCHEMA = z.object({
  zones: z.array(PRESENCE_ZONE_SCHEMA),
  message: z.string().optional().or(z.null()),
});

export type GetPresenceZoneSchema = z.infer<typeof GET_PRESENCE_ZONE_SCHEMA>;

export type PresenceZoneSchema = z.infer<typeof PRESENCE_ZONE_SCHEMA>;

export const convertApiPresenceZoneToBoundingBoxConfig = (zone: PresenceZoneSchema) => {
  return {
    ...zone,
    id: zone?.id || generateVRID(),
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    points: zone.poly || [],
    isDrawing: false,
    isPrevious: true,
    direction_angle_threshold: 0,
    direction_angle: 0,
    detection_area: false,
    dzone_direction: 0,
    checkout_id: zone.checkout_id,
    epos_filters: (zone.epos_filters ?? []).map((filter) => ({
      ...filter,
      id: generateUUID(),
    })),
  };
};

export const getPresenceZonePromise = async (
  display_id: string
): Promise<Array<Omit<BoundingBoxConfig, 'stageWidth' | 'stageHeight' | 'fill'>>> => {
  const response = await ky
    .post(`${ENV.FRONTEND_API_URL}/get_presence_zone`, { body: JSON.stringify({ display_id }) })
    .json();

  const result = GET_PRESENCE_ZONE_SCHEMA.safeParse(response);

  if (!result.success) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const isEmpty = (response as GetPresenceZoneSchema)?.message?.toLowerCase?.() === 'no presence zones.';

    if (isEmpty) return [];

    throw new Error('Invalid response');
  }

  return result.data.zones.map(convertApiPresenceZoneToBoundingBoxConfig);
};
