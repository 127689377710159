export const QUERY_KEY_MAP = {
  useGetPreInitDevices: 'useGetPreInitDevices',
  useGetDetectionZoneData: 'useGetDetectionZoneData',
  useGetPresenceZoneData: 'useGetPresenceZoneData',
  useEditDetectionZone: 'useEditDetectionZone',
  useGetOrgsData: 'useGetOrgsData',
  useGetUserDetails: 'useGetUserDetails',
  useEditPresenceZone: 'useEditPresenceZone',
  useGetStatusImage: 'useGetStatusImage',
  useGetRecentlyViewedDevices: 'useGetRecentlyViewedDevices',
  useEditRecentlyViewedDevices: 'useEditRecentlyViewedDevices',
  useGetZoneNotes: 'useGetZoneNotes',
  useEditZoneNotes: 'useEditZoneNotes',
  useListExternalUsers: 'useListExternalUsers',
  useListAuditLogs: 'useListAuditLogs',
  useGetZoneLastUpdatedMeta: 'useGetZoneLastUpdatedMeta',
  useGetManagementOrgs: 'useGetManagementOrgs',
  useGetManagementStores: 'useGetManagementStores',
  useGetManagementUser: 'useGetManagementUser',
  useGetDeviceZoneVersionHistory: 'useGetDeviceZoneVersionHistory',
  useGetDeviceDetails: 'useGetDeviceDetails',
  useListTasks: 'useListTasks',
  useGetVideoUrl: 'useGetVideoUrl',
  useGetDeviceHealth: 'useGetDeviceHealth',
  useEditExclusionZone: 'useEditExclusionZone',
  useGetExclusionZoneData: 'useGetExclusionZoneData',
  useGetInstallerNotes: 'useGetInstallerNotes',
  useEditInstallerNotes: 'useEditInstallerNotes',
  useGetDownDevices: 'useGetDownDevices',
  useGetRetryTaskConfigId: 'useGetRetryTaskConfigId',
  useListStores: 'useListStores',
  useGetStore: 'useGetStore',
  useGetRecentlyViewedOrgs: 'useGetRecentlyViewedOrgs',
  useRetrieveMaintenanceMode: 'useRetrieveMaintenanceMode',
  useRetrieveIndividualStoreReportFlag: 'useRetrieveIndividualStoreReportFlag',
  useRetrieveWeeklyReportDay: 'useRetrieveWeeklyReportDay',
  useRetrieveWelcomeExperience: 'useRetrieveWelcomeExperience',
  useListFailedReports: 'useListFailedReports',
  useListRetryReportLogs: 'useListRetryReportLogs',
} as const;
